import React from "react"

import "../components/privacypolicy.css"
import Footer from "../components/footer"
import Header2 from "../components/header2"
import Seo from "../components/seo"

const Privacypolicy = () => {
  return (
    <>
      <Seo title="Privacy Policy" />
      <Header2 />

      <div className="container mt-5 pt-5">
        <div>
          <h1 className="font-weight-bolder">Privacy Policy</h1>
          <div className="underline" />
          <div className="mt-4">
            <p>
              Intermedia Marketing Group LLC (“us”, “we”, or “our”) operates the
              <a href="https://superiormerchants.com">
                {" "}
                superiormerchants.com
              </a>{" "}
              website (hereinafter referred to as the “Service”).
            </p>

            <p>
              This page informs you of our policies regarding the collection,
              use and disclosure of personal data when you use our Service and
              the choices you have associated with that data.
            </p>

            <p>
              We use your data to provide and improve the Service. By using the
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless otherwise defined in this
              Privacy Policy, the terms used in this Privacy Policy have the
              same meanings as in our Terms and Conditions, accessible from
              <a href="https://superiormerchants.com"> superiormerchants.com</a>
            </p>
          </div>
        </div>

        <hr className="my-4" />

        <div>
          <h2 className="font-weight-bolder">Definitions</h2>
          <div className="underline" />
          <div class="description-list mt-4">
            <dl>
              <dt>Service</dt>
              <dd>
                Service is the{" "}
                <a href="https://superiormerchants.com">
                  {" "}
                  superiormerchants.com
                </a>
                website operated by Intermedia Marketing Group LLC
              </dd>
              <dt className="mt-3">Personal Data</dt>
              <dd>
                Personal Data means data about a living individual who can be
                identified from those data (or from those and other information
                either in our possession or likely to come into our possession).
              </dd>
              <dt className="mt-3">Usage Data</dt>
              <dd>
                Usage Data is data collected automatically either generated by
                the use of the Service or from the Service infrastructure itself
                (for example, the duration of a page visit).
              </dd>
              <dt className="mt-3">Cookies</dt>
              <dd>
                Cookies are small files stored on your device (computer or
                mobile device)
              </dd>
              <dt className="mt-3">Data Controller</dt>
              <dd>
                <ul>
                  <li>
                    Data Controller means the natural or legal person who
                    (either alone or jointly or in common with other persons)
                    determines the purposes for which and the manner in which
                    any personal information are, or are to be, processed.
                  </li>
                  <li>
                    For the purpose of this Privacy Policy, we are a Data
                    Controller of your Personal Data.
                  </li>
                </ul>
              </dd>
              <dt className="mt-3">Data Processors (or Service Providers)</dt>
              <dd>
                <ul>
                  <li>
                    Data Processor (or Service Provider) means any natural or
                    legal person who processes the data on behalf of the Data
                    Controller.
                  </li>
                  <li>
                    We may use the services of various Service Providers in
                    order to process your data more effectively.
                  </li>
                </ul>
              </dd>
              <dt className="mt-3">Data Subject (or User)</dt>
              <dd>
                Data Subject is any living individual who is using our Service
                and is the subject of Personal Data.
              </dd>
            </dl>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Information Collection and Use</h2>
          <div className="underline" />
          <div className="mt-4">
            <span>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </span>
            <div className="mt-3">
              <h4>Types of Data Collected</h4>
              <dl className="mt-3">
                <dt>Personal Data</dt>
                <dd>
                  While using our Service, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you (“Personal Data”). Personally
                  identifiable information may include, but is not limited to:
                  <ul>
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Address, State, Province, ZIP/Postal code, City</li>
                    <li>Cookies and Usage Data</li>
                  </ul>
                  We may use your Personal Data to contact you with newsletters,
                  marketing or promotional materials and other information that
                  may be of interest to you. You may opt out of receiving any,
                  or all, of these communications from us by following the
                  unsubscribe link or the instructions provided in any email we
                  send.
                </dd>
                <dt>Usage Data</dt>
                <dd>
                  We may also collect information on how the Service is accessed
                  and used (“Usage Data”). This Usage Data may include
                  information such as your computer’s Internet Protocol address
                  (e.g. IP address), browser type, browser version, the pages of
                  our Service that you visit, the time and date of your visit,
                  the time spent on those pages, unique device identifiers and
                  other diagnostic data.
                </dd>
                <dt>Location Data</dt>
                <dd>
                  We may use and store information about your location if you
                  give us permission to do so (“Location Data”). We use this
                  data to provide features of our Service, to improve and
                  customise our Service.
                  <br />
                  You can enable or disable location services when you use our
                  Service at any time by way of your device settings.
                </dd>
                <dt>Tracking Cookies Data</dt>
                <dd>
                  <dl>
                    <dd>
                      We use cookies and similar tracking technologies to track
                      the activity on our Service and we hold certain
                      information.
                    </dd>
                    <dd>
                      Cookies are files with a small amount of data which may
                      include an anonymous unique identifier. Cookies are sent
                      to your browser from a website and stored on your device.
                      Other tracking technologies are also used such as beacons,
                      tags and scripts to collect and track information and to
                      improve and analyse our Service.
                    </dd>
                    <dd>
                      You can instruct your browser to refuse all cookies or to
                      indicate when a cookie is being sent. However, if you do
                      not accept cookies, you may not be able to use some
                      portions of our Service.
                    </dd>
                    <dd>
                      Examples of Cookies we use:
                      <ul>
                        <li>
                          Session Cookies. We use Session Cookies to operate our
                          Service.
                        </li>
                        <li>
                          Preference Cookies. We use Preference Cookies to
                          remember your preferences and various settings
                        </li>
                        <li>
                          Security Cookies. We use Security Cookies for security
                          purposes.
                        </li>
                      </ul>
                    </dd>
                  </dl>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Use of Data</h2>
          <div className="underline" />
          <dl>
            <dd>
              <span>
                Intermedia Marketing Group LLC uses the collected data for
                various purposes:
              </span>
              <ul>
                <li>To provide and maintain our Service</li>
                <li>To notify you about changes to our Service</li>
                <li>
                  To allow you to participate in interactive features of our
                  Service when you choose to do so
                </li>
                <li>To provide customer support</li>
                <li>
                  To gather analysis or valuable information so that we can
                  improve our Service
                </li>
                <li>To monitor the usage of our Service</li>
                <li>To detect, prevent and address technical issues</li>
                <li>
                  To provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you have already
                  purchased or enquired about unless you have opted not to
                  receive such information
                </li>
              </ul>
            </dd>
          </dl>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">
            Legal Basis for Processing Personal Data under the General Data
            Protection Regulation (GDPR)
          </h2>
          <div className="underline" />
          <div className="mt-3">
            <span>
              If you are from the European Economic Area (EEA), Intermedia
              Marketing Group LLC legal basis for collecting and using the
              personal information described in this Privacy Policy depends on
              the Personal Data we collect and the specific context in which we
              collect it.
            </span>
            <dl>
              <dd>
                <span>
                  Intermedia Marketing Group LLC may process your Personal Data
                  because:
                </span>
                <ol>
                  <li>We need to perform a contract with you</li>
                  <li>You have given us permission to do so</li>
                  <li>
                    The processing is in our legitimate interests and it is not
                    overridden by your rights
                  </li>
                  <li>To comply with the law</li>
                </ol>
              </dd>
            </dl>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Retention of Data</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              Intermedia Marketing Group LLC will retain your Personal Data only
              for as long as is necessary for the purposes set out in this
              Privacy Policy. We will retain and use your Personal Data to the
              extent necessary to comply with our legal obligations (for
              example, if we are required to retain your data to comply with
              applicable laws), resolve disputes and enforce our legal
              agreements and policies.
            </p>
            <p>
              Intermedia Marketing Group LLC will also retain Usage Data for
              internal analysis purposes. Usage Data is generally retained for a
              shorter period of time, except when this data is used to
              strengthen the security or to improve the functionality of our
              Service, or we are legally obligated to retain this data for
              longer periods.
            </p>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Transfer of Data</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              Your information, including Personal Data, may be transferred to —
              and maintained on — computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
            </p>
            <p>
              If you are located outside United States and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to United States and process it there.
            </p>
            <p>
              Your consent to this Privacy Policy followed by your submission of
              such information represents your agreement to that transfer.
            </p>
            <p>
              Intermedia Marketing Group LLC will take all the steps reasonably
              necessary to ensure that your data is treated securely and in
              accordance with this Privacy Policy and no transfer of your
              Personal Data will take place to an organisation or a country
              unless there are adequate controls in place including the security
              of your data and other personal information.
            </p>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Disclosure of Data</h2>
          <div className="underline" />
          <div className="mt-3">
            <dl>
              <dt>
                <h4>Disclosure for Law Enforcement</h4>
              </dt>
              <dd>
                Under certain circumstances, Intermedia Marketing Group LLC may
                be required to disclose your Personal Data if required to do so
                by law or in response to valid requests by public authorities
                (e.g. a court or a government agency).
              </dd>
              <dt>
                <h4>Legal Requirements</h4>
              </dt>
              <dd>
                <span>
                  Intermedia Marketing Group LLC may disclose your Personal Data
                  in the good faith belief that such action is necessary to:
                </span>
                <ul>
                  <li>To comply with a legal obligation</li>
                  <li>
                    To protect and defend the rights or property of Intermedia
                    Marketing Group LLC
                  </li>
                  <li>
                    To prevent or investigate possible wrongdoing in connection
                    with the Service
                  </li>
                  <li>
                    To protect the personal safety of users of the Service or
                    the public
                  </li>
                  <li>To protect against legal liability</li>
                </ul>
              </dd>
            </dl>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Security of Data</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">
            Our Policy on “Do Not Track” Signals under the California Online
            Protection Act (CalOPPA)
          </h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              We do not support Do Not Track (“DNT”). Do Not Track is a
              preference you can set in your web browser to inform websites that
              you do not want to be tracked.
            </p>
            <p>
              You can enable or disable Do Not Track by visiting the Preferences
              or Settings page of your web browser.
            </p>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">
            Your Data Protection Rights under the General Data Protection
            Regulation (GDPR)
          </h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              If you are a resident of the European Economic Area (EEA), you
              have certain data protection rights. Intermedia Marketing Group
              LLC aims to take reasonable steps to allow you to correct, amend,
              delete or limit the use of your Personal Data.
            </p>
            <p>
              If you wish to be informed about what Personal Data we hold about
              you and if you want it to be removed from our systems, please
              contact us.
            </p>
            <p>
              In certain circumstances, you have the following data protection
              rights:
            </p>
            <dl>
              <dd>
                <b>
                  The right to access, update or delete the information we have
                  on you.
                </b>{" "}
                Whenever made possible, you can access, update or request
                deletion of your Personal Data directly within your account
                settings section. If you are unable to perform these actions
                yourself, please contact us to assist you.
              </dd>
              <dd>
                <b>The right of rectification.</b> You have the right to have
                your information rectified if that information is inaccurate or
                incomplete.
              </dd>
              <dd>
                <b>The right to object.</b> You have the right to object to our
                processing of your Personal Data.
              </dd>
              <dd>
                <b>The right of restriction.</b> You have the right to request
                that we restrict the processing of your personal information.
              </dd>
              <dd>
                <b>The right to data portability.</b> You have the right to be
                provided with a copy of the information we have on you in a
                structured, machine-readable and commonly used format.
              </dd>
              <dd>
                <b>The right to withdraw consent.</b> You also have the right to
                withdraw your consent at any time where Intermedia Marketing
                Group LLC relied on your consent to process your personal
                information.
              </dd>
              <dd>
                Please note that we may ask you to verify your identity before
                responding to such requests.
              </dd>
              <dd>
                You have the right to complain to a Data Protection Authority
                about our collection and use of your Personal Data. For more
                information, please contact your local data protection authority
                in the European Economic Area (EEA).
              </dd>
            </dl>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Service Providers</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              We may employ third party companies and individuals to facilitate
              our Service (“Service Providers”), provide the Service on our
              behalf, perform Service-related services or assist us in analysing
              how our Service is used.
            </p>
            <p>
              These third parties have access to your Personal Data only to
              perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Analytics</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              We may use third-party Service Providers to monitor and analyse
              the use of our Service.
            </p>
            <dl>
              <dt>Google Analytics</dt>
              <dd>
                <p>
                  Google Analytics is a web analytics service offered by Google
                  that tracks and reports website traffic. Google uses the data
                  collected to track and monitor the use of our Service. This
                  data is shared with other Google services. Google may use the
                  collected data to contextualise and personalise the ads of its
                  own advertising network.
                </p>
                <p>
                  You can opt-out of having made your activity on the Service
                  available to Google Analytics by installing the Google
                  Analytics opt-out browser add-on. The add-on prevents the
                  Google Analytics JavaScript (ga.js, analytics.js and dc.js)
                  from sharing information with Google Analytics about visits
                  activity.
                </p>
                <p>
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy Terms web page:
                  https://policies.google.com/privacy?hl=en
                </p>
              </dd>
            </dl>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Behavioral Remarketing</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              Intermedia Marketing Group LLC uses remarketing services to
              advertise on third party websites to you after you visited our
              Service. We and our third-party vendors use cookies to inform,
              optimise and serve ads based on your past visits to our Service.
            </p>
            <dl>
              <dt>Google AdWords</dt>
              <dd>
                <p>
                  Google AdWords remarketing service is provided by Google Inc.
                </p>
                <p>
                  You can opt-out of Google Analytics for Display Advertising
                  and customise the Google Display Network ads by visiting the
                  Google Ads Settings page: http://www.google.com/settings/ads
                </p>
                <p>
                  Google also recommends installing the Google Analytics Opt-out
                  Browser Add-on – https://tools.google.com/dlpage/gaoptout –
                  for your web browser. Google Analytics Opt-out Browser Add-on
                  provides visitors with the ability to prevent their data from
                  being collected and used by Google Analytics.
                </p>
                <p>
                  For more information on the privacy practices of Google,
                  please visit the Google Privacy Terms web page:
                  https://policies.google.com/privacy?hl=en
                </p>
              </dd>
              <dt>Bing Ads Remarketing</dt>
              <dd>
                <p>
                  Bing Ads remarketing service is provided by Microsoft Inc.
                </p>
                <p>
                  You can opt-out of Bing Ads interest-based ads by following
                  their instructions:
                  https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                </p>
                <p>
                  You can learn more about the privacy practices and policies of
                  Microsoft by visiting their Privacy Policy page:
                  https://privacy.microsoft.com/en-us/PrivacyStatement
                </p>
              </dd>
            </dl>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Links to Other Sites</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party’s site. We strongly advise you to review the
              Privacy Policy of every site you visit.
            </p>
            <p>
              We have no control over and assume no responsibility for the
              content, privacy policies or practices of any third party sites or
              services.
            </p>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Children’s Privacy</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              Our Service does not address anyone under the age of 18
              (“Children”).
            </p>
            <p></p>We do not knowingly collect personally identifiable
            information from anyone under the age of 18. If you are a parent or
            guardian and you are aware that your Child has provided us with
            Personal Data, please contact us. If we become aware that we have
            collected Personal Data from children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Changes to This Privacy Policy</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
            </p>
            <p>
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update the
              “effective date” at the top of this Privacy Policy.
            </p>
            <p>
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
          </div>
        </div>
        <br />

        <div>
          <h2 className="font-weight-bolder">Contact Us</h2>
          <div className="underline" />
          <div className="mt-3">
            <p>
              If you have any questions about this Privacy Policy, please
              contact us:
            </p>
            <ul>
              <li>
                By email:
                <a href="mailto:info@superiormerchants.com">
                  {" "}
                  info@superiormerchants.com
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
export default Privacypolicy
